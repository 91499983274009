import React from 'react';
import Editor from 'react-simple-code-editor';
import PropTypes from 'prop-types';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

const Code = ({code, onValueChange}) =>
  <Editor
    value={code}
    onValueChange={onValueChange}
    highlight={value => highlight(value, languages.js)}
    padding={10}
    style={{
      'fontFamily': '"Fira code", "Fira Mono", monospace',
      'fontSize': 12
    }}
  />

;

Code.propTypes = {
  'code': PropTypes.string,
  'onValueChange': PropTypes.func
};

export default Code;
