import React, { useState } from "react";
import Emoji from "a11y-react-emoji";
import PropTypes from "prop-types";
import {
  UikWidget,
  UikWidgetContent,
  UikSelect,
  UikDivider,
  UikFormInputGroup,
  UikButton
} from "@uik";

const Locations = props => {
  const { depts, cities, isLoading, handleOnClick, handleOnFetch } = props;
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedFav, setSelectedFav] = useState({});

  function handleOnChangeDepts(items) {
    setSelectedDepts(oldArray => [...oldArray, ...items]);
  }

  function handleOnChangeCities(items) {
    setSelectedCities(oldArray => [...oldArray, ...items]);
  }

  function handleOnChangeFav(items) {
    setSelectedFav(items);
  }

  return (
    <UikWidget>
      <UikWidgetContent>
        <h3>
          <Emoji symbol="📌 " label="pin" /> Locations
        </h3>
        <p>Browse and select departments or cities to crawl.</p>
      </UikWidgetContent>
      <UikDivider />
      <UikWidgetContent>
        <UikFormInputGroup horizontal>
          <UikFormInputGroup>
            <h4>Departments</h4>
            <UikSelect
              multi
              placeholder="Select department(s)..."
              onChange={handleOnChangeDepts}
              options={depts}
            />
          </UikFormInputGroup>
          <UikFormInputGroup>
            <h4>Cities</h4>
            <UikSelect
              multi
              placeholder="Select cities(s)..."
              onChange={handleOnChangeCities}
              options={cities}
            />
          </UikFormInputGroup>
          <UikFormInputGroup>
            <h4>Favorite</h4>
            <UikSelect
              placeholder="Select favorite..."
              onChange={handleOnChangeFav}
              options={[
                {
                  label: "Top departments",
                  value: "top-depts",
                  zipcodes: ["78", "92"]
                },
                {
                  label: "Secteur 2022",
                  value: "secteur-2022",
                  zipcodes: [
                    "92380",
                    "92420",
                    "92150",
                    "92500",
                    "78170",
                    "78160",
                    "78400",
                    "78230",
                    "78110",
                    "78600"
                  ]
                },
                {
                  label: "Top 92",
                  value: "top-92",
                  zipcodes: ["92000", "92380", "92500", "92150", "92140"]
                },
                {
                  label: "Top 78",
                  value: "top-78",
                  zipcodes: [
                    "78160",
                    "78100",
                    "78112",
                    "78000",
                    "78220",
                    "78230",
                    "78110"
                  ]
                },
                {
                  label: "Lycées 92",
                  value: "lycees-92",
                  zipcodes: [
                    "92150",
                    "92170",
                    "92310",
                    "92120",
                    "92390",
                    "92600",
                    "92100",
                    "92700",
                    "92330",
                    "92210",
                    "92400",
                    "92800",
                    "92140",
                    "92270",
                    "92290",
                    "92190",
                    "92160",
                    "92500"
                  ]
                },
                {
                  label: "Lycées 78",
                  value: "lycees-78",
                  zipcodes: [
                    "78700",
                    "78100",
                    "78200",
                    "78190",
                    "78300",
                    "78300",
                    "78310",
                    "78370",
                    "78130",
                    "78180",
                    "78000",
                    "78000",
                    "78500",
                    "78160",
                    "78040",
                    "78110",
                    "78210"
                  ]
                },
                {
                  label: "Chatou and co",
                  value: "chatou-and-co",
                  zipcodes: [
                    "78110",
                    "78230",
                    "78360",
                    "78400"
                  ]
                }
              ]}
            />
          </UikFormInputGroup>
        </UikFormInputGroup>
      </UikWidgetContent>
      <UikDivider />
      <UikWidgetContent>
        <UikButton
          primary
          isLoading={isLoading}
          onClick={() =>
            handleOnClick({ selectedCities, selectedDepts, selectedFav })
          }
        >
          <Emoji symbol="📝 " label="note" /> Generate
        </UikButton>
        <UikButton
          isLoading={isLoading}
          onClick={() => handleOnFetch(selectedDepts)}
        >
          <Emoji symbol="🗺 " label="map" /> Fetch cities
        </UikButton>
      </UikWidgetContent>
      <UikDivider />
    </UikWidget>
  );
};

Locations.propTypes = {
  depts: PropTypes.array,
  cities: PropTypes.array,
  isLoading: PropTypes.bool,
  handleOnClick: PropTypes.func,
  handleOnFetch: PropTypes.func
};

export default Locations;
