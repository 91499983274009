import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import Ad from './components/Ad';
import appbase from 'appbase-js';
import Header from './components/Header';
import Menu from './components/Menu';
import Navigation from './components/Navigation';
import Placeholder from './components/Placeholder';
import * as utils from './utils';

import {ReactiveBase, ReactiveList} from '@appbaseio/reactivesearch';

import {
  UikContainerHorizontal,
  UikContainerVertical,
  UikLayoutMain,
  UikNavPanel
} from '@uik';

import styles from './app.module.scss';
import 'typeface-roboto';

var appbaseRef = appbase({
  'url': 'https://ric-api.now.sh',
  'app': 'town-trips'
});

var appbaseEducation = appbase({
  'url': 'https://ric-api.now.sh',
  'app': 'education'
});

/**
 * Persist Array data with localStorage
 * @see https://www.robinwieruch.de/local-storage-react
 * @param  {String} localStorageKey
 * @return {Array}
 */
const useStateWithArrayLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(
    () => {
      const array = utils.local(localStorageKey);
      const items = value ? value.split(',') : [];

      localStorage.setItem(
        localStorageKey,
        Array.from(new Set([...array, ...items]))
      );
    },
    [value]
  );
  return [value, setValue];
};

const onData = (data, metadata) => {
  const {trips, educations} = metadata;

  let education, trip = {};

  if (Object.keys(trips).length) {
    trip = trips[data.city] || {'average': 0};
    trip.delta = trip.average - trips.garches.average;
  }

  if (Object.keys(educations).length) {
    education = educations[data.city];
  }

  return (
    <Ad
      {...data}
      education={education}
      trip={trip}
      key={data.uuid}
      onHide={() => {}}
    />
  );
};

const App = () => {
  /*const [ads, setAds] = useState([]);
  const [search, setSearch] = useState(DEFAULT_SEARCH);
  const [list, {filter, set, sort}] = useList([]);*/
  const [navigation, setNavigation] = useState('Home');
  const [trips, setTrips] = useState({});
  const [educations, setEducations] = useState({});

  useEffect(() => {
    async function fetchData () {
      const response = await appbaseRef.search({
        'body': {
          'aggs': {
            'towns': {
              'terms': {'field': 'city.keyword', 'size': 10000},
              'aggs': {
                'trip': {
                  'filter': {'term': {'trip.withTraffic': true}},
                  'aggs': {
                    'average': {'avg': {'field': 'trip.average'}},
                    'max': {'max': {'field': 'trip.time'}},
                    'min': {'min': {'field': 'trip.time'}}
                  }
                }
              }
            }
          }
        }
      });
      const {buckets} = response.aggregations.towns;
      const bck = buckets.reduce((obj, item) => {
        return {
          ...obj,
          [item.key]: {
            'average': Math.round(item.trip.average.value),
            'max': item.trip.max && Math.round(item.trip.max.value),
            'min': item.trip.min && Math.round(item.trip.min.value)
          }
        };
      }, {});

      setTrips(bck);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData () {
      const response = await appbaseEducation.search({
        'body': {
          'aggs': {
            'lycee': {
              'terms': {
                'field': 'city.keyword',
                'size': 10000
              },
              'aggs': {
                'education': {
                  'aggs': {
                    'number': {
                      'cardinality': {
                        'field': 'name.keyword'
                      }
                    },
                    'va': {
                      'avg': {
                        'field': 'raw_va'
                      }
                    },
                    'success': {
                      'avg': {
                        'field': 'raw_success'
                      }
                    },
                    'presents': {
                      'avg': {
                        'field': 'raw_presents'
                      }
                    },
                    'distinction': {
                      'avg': {
                        'field': 'raw_distinction'
                      }
                    }
                  },
                  'filter': {
                    'bool': {
                      'must': [
                        {
                          'term': {
                            'secteur.keyword': 'SECTEUR PUBLIC'
                          }
                        }
                      ],
                      'should': [
                        {
                          'match_phrase': {
                            'session.keyword': '2019'
                          }
                        },
                        {
                          'match_phrase': {
                            'session.keyword': '2018'
                          }
                        },
                        {
                          'match_phrase': {
                            'session.keyword': '2017'
                          }
                        }
                      ]
                    }
                  }
                }
              }
            }
          }
        }
      });
      const {buckets} = response.aggregations.lycee;
      const bck = buckets.reduce((obj, item) => {
        return {
          ...obj,
          [item.key]: {
            'distinction': Math.round(item.education.distinction.value),
            'number': item.education.number.value || 0,
            'presents': Math.round(item.education.presents.value),
            'success': Math.round(item.education.success.value),
            'va': Math.round(item.education.va.value)
          }
        };
      }, {});

      setEducations(bck);
    }

    fetchData();
  }, []);

  //const request = useFetch(PLAZA_API);

  return (
    <ReactiveBase
      app="empire"
      url="https://ric-api.now.sh"
      className={styles.app}
      theme={{
        'typography': {
          'fontFamily': '"Roboto", sans-serif'
        }
      }}
    >
      <UikContainerHorizontal className={styles.app}>
        <UikContainerVertical>
          <Header />
          <Navigation content={navigation} onClick={setNavigation} />
          <UikContainerHorizontal
            className={classnames(styles.contentContainer, {
              [styles[navigation]]: navigation
            })}
          >
            <UikNavPanel className={styles.panel}>
              <Menu />
            </UikNavPanel>
            <div className={styles.content}>
              <UikLayoutMain>
                <ReactiveList
                  componentId="results"
                  dataField="name"
                  pagination
                  innerClass={{'list': styles.wrapper}}
                  react={{
                    'and': [
                      'city',
                      'favorite',
                      'department',
                      'price',
                      'publication',
                      'seller',
                      'source'
                    ]
                  }}
                  renderItem={data => onData(data, {educations, trips})}
                  size={24}
                  loader={<Placeholder />}
                  sortOptions={[
                    {
                      'label': 'Recently published',
                      'dataField': 'publication',
                      'sortBy': 'desc'
                    },
                    {
                      'label': 'Anciently published',
                      'dataField': 'publication',
                      'sortBy': 'asc'
                    },
                    {
                      'label': 'Expensive',
                      'dataField': 'price',
                      'sortBy': 'desc'
                    },
                    {
                      'label': 'Cheaper',
                      'dataField': 'price',
                      'sortBy': 'asc'
                    },
                    {
                      'label': 'Beds',
                      'dataField': 'beds',
                      'sortBy': 'desc'
                    },
                    {
                      'label': 'Surface',
                      'dataField': 'size',
                      'sortBy': 'desc'
                    }
                  ]}
                />
              </UikLayoutMain>
            </div>
          </UikContainerHorizontal>
        </UikContainerVertical>
      </UikContainerHorizontal>
    </ReactiveBase>
  );
};

export default App;
