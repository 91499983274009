import * as React from 'react';
import classnames from 'classnames/bind';
import {format, formatDistanceToNow, parseISO} from 'date-fns';
import numbro from 'numbro/dist/numbro';
import PropTypes from 'prop-types';
import Emoji from 'a11y-react-emoji';
import {
  formatPositive,
  getTripColor,
  getTripEmoji,
  prettySquare,
  prettyTime,
  rate
} from '../../utils';
import ShowMoreText from 'react-show-more-text';
import {UikWidget, UikonText, Uikon, UikTag, UikStarRating} from '@uik';

import styles from './ad.module.scss';
const cx = classnames.bind(styles);

const Education = props => {
  const {education} = props;

  if (! education || ! education.number) {
    return null;
  }

  return (
    <div className={styles.extra}>
      <div className={styles.desc} title="Education">
        <Emoji symbol="🎓 " label="teacher" />
        <span className={styles.desc}>
          {(education && education.va) || 'n/a'}
        </span>
      </div>
      <div className={styles.desc} title="Award">
        <Emoji symbol="🙌🏾 " label="award" />
        <span className={styles.desc}>{`${(education
          && education.distinction)
          || 'n/a'} - ${(education && education.success)
          || 'n/a'} - ${(education && education.presents) || 'n/a'}`}</span>
      </div>
      <div className={styles.desc} title="School">
        <Emoji symbol="🏫 " label="school" />
        <span className={styles.desc}>{education && education.number}</span>
      </div>
    </div>
  );
};

const Ad = ({
  beds,
  city,
  date,
  description,
  education,
  hidden,
  link,
  onHide,
  photos,
  price,
  publication,
  seller,
  size,
  status,
  source,
  title,
  trip,
  uuid
}) =>
  <UikWidget
    className={cx(styles.wrapper, {'sold': status === 'sold'})}
    margin
    data-testid="ad-widget"
  >
    <div
      data-testid="ad-cover-div"
      className={styles.cover}
      style={{
        'backgroundImage': `url(${photos && photos[0]})`
      }}
    />
    <div className={styles.content}>
      <h3>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </h3>
      <div className={styles.description}>
        <ShowMoreText
          lines={3}
          more="Show more"
          less="Show less"
          expanded={false}
        >
          {description}
        </ShowMoreText>
      </div>
    </div>
    <div className={styles.extra}>
      <div className={styles.desc} title="Price">
        <Emoji symbol="💰" label="moneybag" />
        <span className={styles.desc}>
          {price && numbro(price).format({ optionalMantissa: true,
  average: true,
  mantissa: 1,
  lowPrecision: false})}
        </span>
      </div>
      <div className={styles.desc} title="City">
        <Emoji symbol="📍" label="Pin" />
        <span className={styles.desc}>{city}</span>
      </div>
      <div className={styles.desc} title="Source">
        <Emoji symbol="🖥️" label="Computer" />
        <span className={styles.desc}>{source}</span>
      </div>
    </div>
    <div className={styles.extra}>
      <div className={styles.desc} title="Beds">
        <Emoji symbol="🛏️" label="beds" />
        <span className={styles.desc}>{beds}</span>
      </div>
      <div className={styles.desc} title="Size">
        <Emoji symbol="📏" label="Ruler" />
        <span className={styles.desc}>{size}</span>
      </div>
      <div className={styles.desc} title="Size">
        <Emoji symbol="📐" label="Triangular Ruler" />
        <span className={styles.desc}>
          {prettySquare(price, size)}
        </span>
      </div>
    </div>
    <div className={styles.extra}>
      <div className={styles.desc} title="Trip">
        <Emoji symbol={getTripEmoji(trip.average)} label="Car" />
        <span className={styles.desc}>{prettyTime(trip.average)}</span>
        <UikTag
          className={styles.average}
          color={getTripColor(trip.delta)}
          fill
        >
          {formatPositive(trip.delta)}
        </UikTag>
      </div>
      <div className={styles.desc} title="Time">
        <Emoji symbol="⌚️" label="Watch" />
        <span className={styles.desc}>{`${prettyTime(trip.min)} - ${prettyTime(
          trip.max
        )}`}</span>
      </div>
    </div>
    <Education education={education} />
    <div className={styles.extra}>
      <div className={styles.desc} title="Date">
        <Emoji symbol="📅" label="calendar" />
        <span className={styles.desc}>
          {format(parseISO(date), 'd MMM yy')}
        </span>
      </div>
      <div className={styles.desc} title="Publication">
        <Emoji symbol="⏳" label="hourglass_flowing_sand" />
        <span className={styles.desc}>
          {formatDistanceToNow(parseISO(publication))}
        </span>
      </div>
    </div>
    <div className={styles.extra}>
      <div>
        <UikonText
          highlight={hidden}
          icon={<Uikon>trending_down</Uikon>}
          onClick={() => onHide(uuid)}
        >
          Hide
        </UikonText>
      </div>
      <UikStarRating
        className={cx({
          'pro': seller === 'pro'
        })}
        rating={rate({beds, price, size, trip})}
      />
    </div>
  </UikWidget>

;

Education.propTypes = {
  'education': PropTypes.object
};

Ad.propTypes = {
  'beds': PropTypes.number,
  'city': PropTypes.string.isRequired,
  'date': PropTypes.string.isRequired,
  'description': PropTypes.string,
  'education': PropTypes.object,
  'hidden': PropTypes.bool,
  'link': PropTypes.string.isRequired,
  'onHide': PropTypes.func.isRequired,
  'photos': PropTypes.array,
  'price': PropTypes.number.isRequired,
  'publication': PropTypes.string.isRequired,
  'seller': PropTypes.string.isRequired,
  'size': PropTypes.number,
  'source': PropTypes.string.isRequired,
  'status': PropTypes.bool,
  'title': PropTypes.string.isRequired,
  'trip': PropTypes.object,
  'uuid': PropTypes.string.isRequired
};

export default Ad;
