import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import '@uik/styles.css';
import '@uik/index.scss';
import './App.css';

import App from './App';
import Settings from './Settings';

require('dotenv').config();

ReactDOM.render(
  <Router>
    <Switch>
      <Route path={['/settings/:depts', '/settings']} component={Settings} />
      <Route path="/" component={Settings} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
