const {REACT_APP_PLAZA_API: PLAZA_API = ''} = process.env; //eslint-disable-line

const DEFAULT_SEARCH = 'garches-500-700';
const SEARCH = [
  {
    'id': DEFAULT_SEARCH,
    'label': 'Around Garches 500k - 750k',
    'links': [
      'https://www.leboncoin.fr/recherche?category=9&locations=Vaucresson_92420,Saint-Cloud_92210,Suresnes_92150,Rueil-Malmaison_92500,Garches_92380&real_estate_type=1&price=500000-750000',
      'https://www.pap.fr/annonce/vente-maisons-suresnes-92150-g43273g43283g43287g43296g43298-jusqu-a-750000-euros',
      'https://www.seloger.com/list.htm?types=2&projects=2&enterprise=0&natures=1%2C2&price=500000%2F750000&places=%5B%7Bci%3A920063%7D%7C%7Bci%3A920033%7D%7C%7Bci%3A920073%7D%7C%7Bci%3A920064%7D%7C%7Bci%3A920076%7D%5D&qsVersion=1.0',
      'https://www.superimmo.com/achat/maison/rueil-malmaison-92500,suresnes-92150,saint-cloud-92210,garches-92380,vaucresson-92420?price_max=750000.0&price_min=500000.0'
    ]
  },
  {
    'id': 'ligne-l-500-750',
    'label': 'Ligne L 500k - 750k',
    'links': [
      'https://www.leboncoin.fr/recherche/?category=9&locations=Versailles_78000__48.80396_2.11956_3987,Viroflay_78220__48.80295_2.17254_1728,Chaville_92370__48.80909_2.19245_1491,Saint-Nom-la-Bret%C3%A8che_78860__48.85948_2.02328_2729,L%27Etang-la-Ville_78620__48.87043_2.07255_2661,Marly-le-Roi_78160__48.86781_2.09693_2423,Louveciennes_78430__48.86174_2.11216_2324,Bougival_78380__48.85989_2.13934_1977,La%20Celle-Saint-Cloud_78170__48.85267_2.14363_2748&real_estate_type=1&price=500000-750000',
      'https://www.pap.fr/annonce/vente-maisons-versailles-78000-g39053g39069g39077g39133g39135g39172g39216g39282g43286g43292g43297-jusqu-a-750000-euros',
      'https://www.seloger.com/list.htm?types=2&projects=2&enterprise=0&natures=1%2C2&price=500000%2F750000&places=%5B%7Bci%3A780126%7D%7C%7Bci%3A780092%7D%7C%7Bci%3A780350%7D%7C%7Bci%3A780372%7D%7C%7Bci%3A780224%7D%7C%7Bci%3A780571%7D%7C%7Bci%3A920077%7D%7C%7Bci%3A920072%7D%7C%7Bci%3A920022%7D%7C%7Bci%3A780686%7D%7C%7Bci%3A780646%7D%5D&qsVersion=1.0',
      'https://www.superimmo.com/achat/maison/chaville-92370,versailles-78000,la-celle-saint-cloud-78170,marly-le-roi-78160,viroflay-78220,bougival-78380,louveciennes-78430,saint-nom-la-breteche-78860,l-etang-la-ville-78620?price_max=750000.0&price_min=500000.0'
    ]
  },
  {
    'id': 'nanterre-500-750',
    'label': 'Nanterre 500k - 750k',
    'links': [
      'https://www.leboncoin.fr/recherche?category=9&locations=Nanterre_92000&real_estate_type=1&price=500000-750000',
      'https://www.pap.fr/annonce/vente-maisons-nanterre-92000-g43265-jusqu-a-750000-euros',
      'https://www.seloger.com/list.htm?projects=2&types=2&natures=1,2&places=[{ci:920050}]&price=500000/750000&enterprise=0&qsVersion=1.0',
      'https://www.superimmo.com/achat/maison/nanterre-92000?price_max=750000.0&price_min=500000.0'
    ]
  },
  {
    'id': 'garches',
    'label': 'Garches',
    'links': [
      'https://www.leboncoin.fr/recherche?category=9&locations=Garches_92380&real_estate_type=1',
      'https://www.pap.fr/annonce/vente-maisons-garches-92380-g43287',
      'https://www.seloger.com/list.htm?projects=2&types=2&natures=1,2&places=[{ci:920033}]&enterprise=0&qsVersion=1.0',
      'https://www.superimmo.com/achat/maison/garches-92380'
    ]
  },
  {
    'id': 'dnb',
    'label': 'DNB',
    'links': [
      'https://www.leboncoin.fr/recherche/?category=9&locations=Vanves_92170,Sceaux_92330,Clamart_92140&real_estate_type=1&price=500000-750000',
      'https://www.pap.fr/annonce/vente-maisons-clamart-92140-g43268g43274g43291-jusqu-a-750000-euros',
      'https://www.seloger.com/list.htm?projects=2&types=2&natures=1,2&places=[{ci:920023}|{ci:920075}|{ci:920071}]&price=500000/750000&enterprise=0&qsVersion=1.0',
      'https://www.superimmo.com/achat/maison/clamart-92140,vanves-92170,sceaux-92330?price_max=750000.0&price_min=500000.0'
    ]
  }
];

const DEFAULT_LOCATIONS = [
  '78100',
  '78000',
  '78220',
  '78380',
  '78170',
  '78560',
  '78160',
  '78430',
  '78112',
  '78110',
  '92000',
  '92330',
  '92380',
  '92170',
  '92420',
  '92210',
  '92200',
  '92140',
  '92310',
  '92240',
  '92500',
  '92190',
  '92360',
  '92120',
  '92320',
  '92370',
  '92150'
];

export {DEFAULT_LOCATIONS, DEFAULT_SEARCH, PLAZA_API, SEARCH};
