import React from "react";
import Emoji from 'a11y-react-emoji';
import Code from "../Code";
import PropTypes from "prop-types";
import { getDomain } from "../../../utils";
import {
  UikWidget,
  UikWidgetContent,
  UikDivider,
  UikCheckbox,
  UikFormInputGroup,
  UikButton,
  UikNavLink
} from "@uik";

const Search = props => {
  const {
    payload,
    isLoading,
    message,
    handleOnValueChange,
    handleOnClickScrape,
    handleOnChangeSource,
    handleOnClickSources,
    sources
  } = props;

  const formatCode = value => {
    return JSON.stringify(value, null, 2);
  };

  const isReady = value => {
    return value && payload.links.length;
  };

  const Links = ({value}) => (
    <div>
      {value.links.map((link, index) => (
        <UikNavLink key={index} icon={link.icon} href={link.href} target="_blank" rel="noopener noreferrer">
          {link.details}
        </UikNavLink>
      ))}
    </div>
  );

  return (
    <UikWidget>
      <UikWidgetContent>
        <h3><Emoji symbol="🕵🏽‍♀️ " label="detective" />Search</h3>
        <p>
          Receive notifications, requests to write a review, pricing notices,
          and other reminders related to your activities on Building platform.
        </p>
      </UikWidgetContent>
      <UikDivider />
      <UikWidgetContent>
        <h4>Payload</h4>

        <Code code={formatCode(payload)} onValueChange={handleOnValueChange} />
        <UikButton
          disabled={!isReady(payload)}
          success
          isLoading={isLoading}
          onClick={handleOnClickScrape}
        >
          Search
        </UikButton>
        <UikButton onClick={() => {}}>reset</UikButton>
        <UikButton onClick={handleOnClickSources}><Emoji symbol="👩🏽‍💻" label="newTab" /></UikButton>
        <Code code={message} onValueChange={() => {}} />
      </UikWidgetContent>
      <UikWidgetContent>
        <UikFormInputGroup>
          <h4>Sources</h4>
          {Object.entries(sources).map(([key, value], index) => {
            return (
              <div key={index}>
                <strong>{key}</strong>
                <Links value={value} />
              </div>
            );
          })}
        </UikFormInputGroup>
      </UikWidgetContent>
    </UikWidget>
  );
};

Search.propTypes = {
  isLoading: PropTypes.bool,
  handleOnValueChange: PropTypes.func,
  handleOnClickScrape: PropTypes.func,
  handleOnChangeSource: PropTypes.func,
  handleOnClickSources: PropTypes.func,
  message: PropTypes.string,
  payload: PropTypes.object,
  sources: PropTypes.object
};

export default Search;
