import * as React from 'react';
import diff from 'hyperdiff';
import {UikSelect} from '@uik';
import {MultiDropdownList} from '@appbaseio/reactivesearch';

import styles from './dropdown.module.scss';

const getItem = array => {
  return array.added[0] || array.removed[0];
};

const UikSelectLoader
  = <div key="UikSelectLoader" className={styles.menuContainer}>
    <UikSelect placeholder="Loading..." options={['Fetching results']} />
  </div>

;

const Dropdown = props => {
  let selected = [];

  return (
    <MultiDropdownList
      {...props}
      innerClass={{
        'select': styles.searchInput
      }}
      render={({loading, error, data, handleChange}) => {
        if (loading) {
          return UikSelectLoader;
        }

        if (error) {
          return (
            <div>
              Something went wrong! Error details {JSON.stringify(error)}
            </div>
          );
        }

        const options = data.map(item => {
          return {
            'value': item.key,
            'label': `${item.key} (${item.doc_count})`
          };
        });
        const defaultValue = selected.map(source => source.value);

        return (
          <div key="UikSelect" className={styles.menuContainer}>
            <UikSelect
              multi
              placeholder="Select source(s)..."
              defaultValue={defaultValue}
              onChange={item => {
                const thediff = getItem(diff(selected, item, 'value'));

                selected = Array.from(item);
                handleChange(thediff.value);
              }}
              options={options}
            />
          </div>
        );
      }}
    />
  );
};

export default Dropdown;
