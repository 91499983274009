import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDomain } from "../utils";
import Header from "../components/Header";
import Locations from "./components/Locations";
import Search from "./components/Search";
import useFetch from "use-http";
import { UikWidgetContainer, UikContainerVertical, UikLayoutMain } from "@uik";
import { useLocation } from "react-router-dom";

import { DEFAULT_LOCATIONS } from "../constants";

import styles from "./settings.module.scss";

const I18N = {
  'labels': ["depts", "cities", "fav"],
  'emojis': ["🏘 ", "🌇 ", "💒 "]
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function compare(a, b) {
  const bandA = a.label.toUpperCase();
  const bandB = b.label.toUpperCase();

  let comparison = 0;

  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

const formatFromRecords = records => {
  return records
    .map(record => {
      const label = record.fields.nom_comm.toLowerCase();
      const zipcode = record.fields.postal_code;

      return {
        zipcode,
        label: `${label} - ${zipcode}`,
        value: `${zipcode}-${label}`
      };
    })
    .sort(compare);
};

const formatFromDepts = departments => {
  return departments
    .map(department => {
      const value = department.value;

      return {
        label: department.label,
        value: `${value}-${value}`,
        zipcode: value
      };
    })
    .sort(compare);
};

const formatPayload = (payload, details) => {
  try {
    return payload.links.map(link => {
      const label = getDomain(link);

      return {
        label,
        details,
        href: link
      };
    });
  } catch (error) {
    console.error(error);
    return { label: error, href: "#" };
  }
};

const Settings = () => {
  const [depts, setDepts] = useState([]);
  const [cities, setCities] = useState([]);
  const [sources, setSources] = useState({});
  const [selectedSources, setSelectedSources] = useState([]);
  const [message, setMessage] = useState("");
  const [payload, setPayload] = useState({ links: [] });
  const [original, setOriginal] = useState(payload);
  const [price, setPrice] = useState(0);
  const request = useFetch(
    "https://discovery.opendatasoft.com/api/records/1.0"
  );
  const opendatasoft = useFetch(
    "https://public.opendatasoft.com/api/records/1.0"
  );
  const [plaza, response] = useFetch("https://plaza.vercel.app");

  const query = useQuery();

  useEffect(() => {
    setPrice(query.get("price"));
  }, []);

  useEffect(() => {
    async function fetch() {
      const response = await request.get(
        `/search/?dataset=liste-des-departements-metropole-dom&rows=150`
      );

      const fields = response.records.map(record => record.fields);
      const depts = fields
        .map(field => {
          return {
            label: `${field.nom_du_departement} (${field.code_texte})`,
            value: field.code_texte,
            zipcode: field.code_texte
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

      setDepts(depts);
    }
    fetch();
  }, []);

  useEffect(() => {
    const links = original.links.filter(
      item => !selectedSources.includes(getDomain(item))
    );

    setPayload({ links });
  }, [selectedSources]);

  async function fetchCities(departments) {
    departments = Array.from(new Set(departments.map(a => a.value))).map(
      value => {
        return departments.find(a => a.value === value);
      }
    );
    const promises = departments.map(
      async dept =>
        await opendatasoft.get(
          `/search/?dataset=correspondance-code-insee-code-postal&rows=1000&facet=insee_com&refine.code_dept=${dept.value}`
        )
    );
    const results = await Promise.all(promises);
    const cities = results.map(result => formatFromRecords(result.records));

    setCities(cities.flat());
  }

  async function genPayload({ selectedCities, selectedDepts, selectedFav }) {
    const uniqueCities = selectedCities.reduce(
      (x, y) => (x.findIndex(e => e.label === y.label) < 0 ? [...x, y] : x),
      []
    );
    const uniqueDepts = selectedDepts.reduce(
      (x, y) => (x.findIndex(e => e.label === y.label) < 0 ? [...x, y] : x),
      []
    );

    const depts =
      (uniqueDepts.length && uniqueDepts.map(item => item.zipcode)) || [];
    const fav =
      (Object.keys(selectedFav).length && selectedFav.zipcodes) || [];
    const cities =
      (uniqueCities.length && uniqueCities.map(item => item.zipcode)) ||
      (!depts.length && !fav.length && DEFAULT_LOCATIONS) ||
      [];

    // Generate payloads for selected departments AND cities
    const promises = [depts, cities, fav].map(async (zipcodes, index) => {
      if (!zipcodes.length) {
        return {};
      }

      const what = I18N.labels[index];
      const icon = I18N.emojis[index];
      const details = `${zipcodes.length} ${what} with max price ${price ||
        '"all"'}`;
      const { links } = await plaza.post("/payload", {
        zipcodes,
        query: {
          price
        }
      });

      return { details, icon, links };
    });
    const results = await Promise.all(promises);

    const sources = results.reduce((obj, result, index) => {
      if (!result.links) {
        return obj;
      }

      const { details, icon } = result;
      result.links.forEach(link => {
        const label = getDomain(link);

        if (!obj[label]) {
          obj[label] = {
            links: [
              {
                icon,
                label,
                details,
                href: link
              }
            ]
          };
        } else {
          obj[label].links.push({
            icon,
            label,
            details,
            href: link
          });
        }
      });
      return obj;
    }, {});

    setSources(sources);
  }

  function handleOnValueChange(value) {
    try {
      setPayload(JSON.parse(value));
    } catch (error) {
      console.error(error);
    }
  }

  function handleOnChangeSource({ checked, source }) {
    setSelectedSources(oldArray => {
      const newArray = [...oldArray, source];

      if (checked) {
        return newArray.filter(value => value !== source);
      }

      return newArray;
    });
  }

  function handleOnClickSources () {
    Object.entries(sources).forEach(([key, value]) => {
      const {links} = value;
      const link = links[links.length - 1]

      window.open(link.href, '_blank');
    });
  }

  async function scrape() {
    const results = await plaza.post("/es", payload);

    if (response.ok) {
      setMessage(JSON.stringify(results, null, 2));
    }
  }

  return (
    <UikContainerVertical className={styles.page}>
      <Header />
      <UikLayoutMain>
        <UikWidgetContainer>
          <Locations
            depts={depts}
            cities={cities}
            isLoading={plaza.loading}
            handleOnClick={genPayload}
            handleOnFetch={fetchCities}
          />
          <Search
            payload={payload}
            isLoading={plaza.loading}
            handleOnValueChange={handleOnValueChange}
            handleOnClickScrape={scrape}
            handleOnChangeSource={handleOnChangeSource}
            handleOnClickSources={handleOnClickSources}
            message={message}
            sources={sources}
          />
        </UikWidgetContainer>
      </UikLayoutMain>
    </UikContainerVertical>
  );
};

export default Settings;
