import * as React from 'react';
import Emoji from 'a11y-react-emoji';
import {Link} from 'react-router-dom';
import {
  UikButton,
  Uikon,
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle
} from '@uik';

import styles from './header.module.scss';

const Header = () =>
  <UikTopBar>
    <UikTopBarSection>
      <UikTopBarTitle large Component={Link} to="/">
        <Emoji symbol="🏡" label="house_with_garden" className={styles.emoji} />{' '}
        Ma Maison
      </UikTopBarTitle>
    </UikTopBarSection>
    <UikButton
      className={styles.settings}
      clear
      icon={<Uikon>settings</Uikon>}
      iconOnly
      Component={Link}
      to="/settings"
    />
  </UikTopBar>

;

export default Header;
