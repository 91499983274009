import {compareAsc, compareDesc, parseISO} from 'date-fns';
import numbro from 'numbro/dist/numbro';
import parseDomain from 'parse-domain';
import prettyMilliseconds from 'pretty-ms';

export const sort = {
  'publication.asc': (a, b) => {
    return compareAsc(parseISO(a.publication), parseISO(b.publication));
  },
  'publication.desc': (a, b) => {
    return compareDesc(parseISO(a.publication), parseISO(b.publication));
  },
  'price.desc': (a, b) => {
    return b.price - a.price;
  },
  'price.asc': (a, b) => {
    return a.price - b.price;
  },
  'beds': (a, b) => {
    return b.beds - a.beds;
  },
  'surface': (a, b) => {
    return b.size - a.size;
  }
};

/**
 * Get item - in Array format - from LocaalStorage
 * @param  {String} localStorageKey
 * @return {Array}
 */
export function local (localStorageKey) {
  const item = localStorage.getItem(localStorageKey);

  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  return item ? item.split(',') : [];
}

/**
 * [isHidden description]
 * @param  {[type]}  uuid [description]
 * @return {Boolean}      [description]
 */
export function isHidden (uuid, localStorageKey = 'ma-maison') {
  return local(localStorageKey).includes(uuid);
}

const RATING = {
  'beds': beds => {
    if (beds >= 3) {
      return 1;
    }

    return 0;
  },
  'price': price => {
    if (price <= 700000) {
      return 1;
    }

    if (price >= 800000) {
      return - 1;
    }

    return 0;
  },
  'size': size => {
    return size >= 90;
  },
  'trip': trip => {
    if (trip.average < 45) {
      return 1;
    }

    if (trip.average > 60) {
      return - 1;
    }

    return 0;
  }
};

/**
 * Rate the ad
 * @param  {Number} beds    [description]
 * @param  {String} seller  [description]
 * @param  {Number} size    [description]
 * @param  {Object} trip [description]
 * @return {Number}         [description]
 */
export function rate ({beds, price, size, trip}) {
  return (
    RATING.beds(beds)
    + RATING.price(price)
    + RATING.size(size)
    + RATING.trip(trip)
  );
}

export function getDomain (link) {
  try {
    const {domain} = parseDomain(link);

    return domain;
  } catch (error) {
    return link;
  }
}

export function formatPositive (value) {
  if (Math.sign(value) === 1) {
    return `+${value}`;
  }

  return value;
}

export function prettyTime (value) {
  if (! isNaN(value)) {
    return prettyMilliseconds(value * 60000);
  }

  return value;
}

export function prettySquare (price, size) {
  try {
    return numbro(Math.round(price / + size)).format({'thousandSeparated': true});
  } catch (error) {
    return 'n/a';
  }
}

export function getTripColor (delta) {
  if (Math.sign(delta) === 1) {
    if (Math.abs(delta) <= 15) {
      return 'orange';
    }

    return 'red';
  }

  return 'green';
}

export function getTripEmoji (average) {
  return average <= 45 ? '🏎 ' : '🚙 ';
}
